import React from 'react';
import { Dialog, DialogContent } from '@material-ui/core';
import { Button, ButtonTypes } from 'app/components/Material/Inputs/Button';
import { Tooltip } from 'app/components/Material/Tooltip';
import moment from 'moment';
import styles from '../SubscriptionPlans.module.scss';

function DowngradeConfirmationModal({
  open,
  onClose,
  onConfirm,
  product,
  usage,
  expiryDate,
}) {
  const { projects, asinAmount, keywordsAmount } = usage;

  const limitBlocks = [
    {
      icon: 'icon-project-plus',
      title: 'Projects',
      tooltip: `You currently have ${projects} projects, but when your account downgrades to the new plan, your allowance will be ${product.restrictions.projectsLimit || 999} projects.`,
      newLimit: product.restrictions.projectsLimit || 999,
      currentUsage: projects,
    },
    {
      icon: 'icon-asin-insight',
      title: 'ASINs',
      tooltip: `You are currently tracking ${asinAmount} ASINs, but when your account downgrades to the new plan, your allowance will be ${product.restrictions.asinsLimit || 0} ASINs.`,
      newLimit: product.restrictions.asinsLimit || 0,
      currentUsage: asinAmount,
    },
    {
      icon: 'icon-keyword-spy',
      title: 'Keywords tracked',
      tooltip: `You are currently tracking ${keywordsAmount} keywords, but when your account downgrades to the new plan, your allowance will be ${product.restrictions.keywordsLimit || 0} keywords.`,
      newLimit: product.restrictions.keywordsLimit || 0,
      currentUsage: keywordsAmount,
    },
  ].filter(block => block.currentUsage > block.newLimit);

  const formattedDate = moment(expiryDate).format('MMM DD');

  return (
    <Dialog open={open} onClose={onClose} classes={{ paper: styles.modal }}>
      <DialogContent>
        <div className={styles.modalContent}>
          <h2 className={styles.modalTitle}>Before You Downgrade</h2>
          <p className={styles.modalDescription}>
            On {formattedDate}, if your usage still exceeds the limits of your
            new plan after the downgrade, you will lose access to certain
            features, such as Ranks and Trends.
          </p>

          {limitBlocks.length > 0 && (
            <div className={styles.limitBlocks}>
              {limitBlocks.map((block, index) => (
                <div key={index} className={styles.limitBlock}>
                  <div className={styles.iconWrapper}>
                    <img
                      src={`${window.location.origin}/media/icons/svg/AsideLeft/${block.icon}.svg`}
                      alt={block.title}
                      style={{ width: 20 }}
                    />
                  </div>
                  <div className={styles.blockContent}>
                    <div className={styles.blockTitle}>
                      <span>{block.title}</span>
                      <Tooltip title={block.tooltip} showInfoIcon={true} />
                    </div>
                    <div className={styles.blockInfo}>
                      <span className={styles.newLimit}>{block.newLimit}</span>
                      <img
                        src={`${window.location.origin}/media/icons/svg/AsideLeft/arrow-right.svg`}
                        alt="arrow"
                        style={{ width: 10 }}
                      />
                      <span className={styles.usageAmount}>
                        {block.currentUsage}
                      </span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}

          <div className={styles.modalButtons}>
            <Button type={ButtonTypes.secondary} onClick={onConfirm}>
              Downgrade
            </Button>
            <span className={styles.cancelButton} onClick={onClose}>
              I've changed my mind
            </span>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default DowngradeConfirmationModal;
