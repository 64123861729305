import React, { lazy, Suspense, useEffect } from 'react';
import { actions as authActions } from 'app/store/ducks/auth/actions';
import { shallowEqual, useSelector } from 'react-redux';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useLastLocation } from 'react-router-last-location';

import { componentLoader } from 'app/utils/componentLoader';
import { LayoutContextProvider, LayoutSplashScreen } from '../../_metronic';
import * as routerHelpers from '../router/RouterHelpers';
import Layout from '../../_metronic/layout/Layout';
import '../../_metronic/_assets/sass/pages/login/login-1.scss';
import { PrivateRouteWrapper } from 'app/Wrappers/PrivateRouteWrapper';
import { PublicRouteWrapper } from 'app/Wrappers/PublicRouteWrapper';
import {
  useIsTeamMenuFeatureEnabled,
  useIsSettingConnectionMenuFeatureEnabled,
} from 'app/utils/posthog';

// Pages
import ConfirmationCompleted from 'app/pages/confirmationCompleted/confirmationCompleted';
import NoSubscription from 'app/pages/no-subscription/NoSubscription';
import NetworkError from 'app/pages/network-error/NetworkError';
import AccessDenied from 'app/pages/access-denied/AccessDenied';
import LogoutPage from 'app/pages/auth/Logout';
import ForgotPasswordConfirm from 'app/pages/auth/ForgotPasswordConfirm/ForgotPasswordConfirm';
import ForgotPassword from 'app/pages/auth/ForgotPassword';
import SendVerification from 'app/pages/auth/SendVerification/SendVerification';
import Login from 'app/pages/auth/Login';
import Registration from 'app/pages/auth/Registration/Registration';
import ResendEmail from 'app/pages/auth/ResendEmail';
import SubscriptionPlansPage from 'app/pages/auth/SubscriptionPlans/SubscriptionPlans';
import CompletePurchasePage from 'app/pages/auth/CompletePurchase/CompletePurchase';

// Actions
import { AuthActionTypes as authActionTypes } from './../store/ducks/auth/actionTypes';
import * as Sentry from '@sentry/react';

const SentryRoute = Sentry.withSentryRouting(Route);

const KeywordSpyPage = lazy(() =>
  componentLoader(() => import('app/pages/KeywordSpy')),
);
const AsinInsightsPage = lazy(() =>
  componentLoader(() => import('app/pages/AsinInsights')),
);

const CompetitorAnalysisPage = lazy(() =>
  componentLoader(() => import('app/pages/competitor-analysis')),
);
const SearchByASINPage = lazy(() =>
  componentLoader(() => import('app/pages/home/SearchByASIN')),
);
const DownloadReportPage = lazy(() =>
  componentLoader(() => import('app/pages/new-csv-download')),
);
const AsinSalesPage = lazy(() =>
  componentLoader(() => import('app/pages/asin-sales')),
);
const Seasonality = lazy(() =>
  componentLoader(() => import('app/pages/seasonality')),
);
const AsinAnalysis = lazy(() =>
  componentLoader(() => import('app/pages/asin-analysis')),
);
const MultipleAsinsAnalysis = lazy(() =>
  componentLoader(() => import('app/pages/multiple-asins-analysis')),
);
const KeywordRelevancy = lazy(() =>
  componentLoader(() => import('app/pages/asin-keywords-relevancy')),
);
const SuggestedKeywords = lazy(() =>
  componentLoader(() => import('app/pages/suggested-keywords')),
);
// const SettingsPage = lazy(() => componentLoader(() => import("app/pages/settings")));
const BillingPage = lazy(() =>
  componentLoader(() => import('app/pages/settings/billing/BillingContainer')),
);
const ConnectionsPage = lazy(() =>
  componentLoader(() => import('app/pages/settings/connections')),
);
const UserProfilePage = lazy(() =>
  componentLoader(() =>
    import('app/pages/settings/account/AccountSettingsContainer'),
  ),
);
const CompanyProfilePage = lazy(() =>
  componentLoader(() => import('app/pages/settings/company-profile')),
);
const TeamPage = lazy(() =>
  componentLoader(() => import('app/pages/settings/Team')),
);
// const TeamPageTest = lazy(() => componentLoader(() => import("app/pages/settings/TeamTest")));
const ChangePassword = lazy(() =>
  componentLoader(() => import('app/pages/auth/ChangePassword/ChangePassword')),
);
const Projects = lazy(() =>
  componentLoader(() => import('app/pages/projects')),
);
const ProjectDetails = lazy(() =>
  componentLoader(() => import('app/pages/projects/projectDetails')),
);
const UpgradePlanPage = lazy(() =>
  componentLoader(() => import('app/pages/settings/plan/UpgradePlan')),
);

const KeywordSpyPageRoute = () => (
  <PrivateRouteWrapper Component={KeywordSpyPage} />
);
const AsinInsightsPageRoute = () => (
  <PrivateRouteWrapper Component={AsinInsightsPage} />
);

const LoginPageRoute = () => <PublicRouteWrapper Component={Login} />;
const RegistrationPageRoute = () => (
  <PublicRouteWrapper Component={Registration} />
);
const ForgotPasswordPageRoute = () => (
  <PublicRouteWrapper Component={ForgotPassword} />
);
const SendVerificationRoute = () => (
  <PublicRouteWrapper Component={SendVerification} />
);
const ConfirmPasswordPageRoute = () => (
  <PublicRouteWrapper Component={ForgotPasswordConfirm} />
);
const EmailConfirmPageRoute = () => (
  <PublicRouteWrapper Component={ConfirmationCompleted} />
);
const ResendEmailPageRoute = () => (
  <PublicRouteWrapper Component={ResendEmail} />
);
const LogoutPageRoute = () => <LogoutPage />;
const AsinSalesV2PageRoute = () => (
  <PrivateRouteWrapper Component={AsinSalesPage} />
);
const DownloadReportPageRoute = () => (
  <PrivateRouteWrapper Component={DownloadReportPage} />
);
const CompetitorAnalysisPageRoute = () => (
  <PrivateRouteWrapper Component={CompetitorAnalysisPage} />
);
const SeasonalityPageRoute = () => (
  <PrivateRouteWrapper Component={Seasonality} />
);
const SearchByAsinPageRoute = () => (
  <PrivateRouteWrapper Component={SearchByASINPage} />
);
const AsinAnalysisPageRoute = () => (
  <PrivateRouteWrapper Component={AsinAnalysis} />
);
const MultipleAsinsAnalysisPageRoute = () => (
  <PrivateRouteWrapper Component={MultipleAsinsAnalysis} />
);
const KeywordsRelevancyPageRoute = () => (
  <PrivateRouteWrapper Component={KeywordRelevancy} />
);
const SuggestedKeywordsPageRoute = () => (
  <PrivateRouteWrapper Component={SuggestedKeywords} />
);
const ChangePasswordPageRoute = () => (
  <PrivateRouteWrapper Component={ChangePassword} />
);
const ProjectsPageRoute = () => <PrivateRouteWrapper Component={Projects} />;
const ProjectDetailsPageRoute = () => (
  <PrivateRouteWrapper Component={ProjectDetails} />
);
const ProjectDetailsPrimaryAsinPageRoute = () => (
  <PrivateRouteWrapper Component={ProjectDetails} />
);
// const SettingsPageRoute = () => <PrivateRouteWrapper Component={SettingsPage} />;
const BillingPageRoute = () => <PrivateRouteWrapper Component={BillingPage} />;
const ConnectionsPageRoute = () => (
  <PrivateRouteWrapper Component={ConnectionsPage} />
);
const UserProfilePageRoute = () => (
  <PrivateRouteWrapper Component={UserProfilePage} />
);
const CompanyProfilePageRoute = () => (
  <PrivateRouteWrapper Component={CompanyProfilePage} />
);
const TeamPageRoute = () => <PrivateRouteWrapper Component={TeamPage} />;
// const TeamPageTestRoute = () => <PrivateRouteWrapper Component={TeamPageTest} />;
const UpgradePlanPageRoute = () => (
  <PrivateRouteWrapper Component={UpgradePlanPage} />
);
const SubscriptionPlansPageRoute = () => (
  <PrivateRouteWrapper Component={SubscriptionPlansPage} />
);
const CompletePurchasePageRoute = () => (
  <PrivateRouteWrapper Component={CompletePurchasePage} />
);
const NoSubscriptionPageRoute = () => (
  <PrivateRouteWrapper Component={NoSubscription} />
);
const NetworkErrorPageRoute = () => (
  <PrivateRouteWrapper Component={NetworkError} />
);
const AccessDeniedPageRoute = () => (
  <PrivateRouteWrapper Component={AccessDenied} />
);

export const Routes = withRouter(({ history }) => {
  const dispatch = useDispatch();
  const isTeamMenuFeatureEnabled = useIsTeamMenuFeatureEnabled();

  const lastLocation = useLastLocation();
  routerHelpers.saveLastLocation(lastLocation);
  const user = useSelector((state) => state.auth.user);
  const isAuthorized = user != null && user.sub != null;

  let nowLocation = window.location.pathname;
  history.listen((location, action) => {
    if (location.pathname !== nowLocation) {
      dispatch({ type: authActionTypes.LocationChange });
      nowLocation = location.pathname;
    }
  });

  const userDetails = JSON.parse(localStorage.getItem('userDetails'));
  if (userDetails && !userDetails.currentRestrictions) {
    dispatch({ type: authActionTypes.FetchUserDetails });
  }

  useEffect(() => {
    if (isAuthorized) {
      dispatch(authActions.fetchUserDetails());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthorized]);

  const { menuConfig } = useSelector(
    ({ auth, builder: { menuConfig } }) => ({
      menuConfig,
      isAuthorized: auth.user != null && auth.user.sub != null,
      userPlan: auth.userPlan,
      userLastLocation: routerHelpers.getLastLocation(),
      expiredPlanError: auth.expiredPlanError,
    }),
    shallowEqual,
  );

  const isSettingConnectionMenuFeatureEnabled =
    useIsSettingConnectionMenuFeatureEnabled();

  return (
    <LayoutContextProvider history={history} menuConfig={menuConfig}>
      <Switch>
        <Redirect exact from="/auth" to="/auth/login" />
        <Redirect exact from="/asin-sales" to="/asin-insights" />
        <Redirect exact from="/seasonality" to="/projects" />
        <SentryRoute path="/auth/login" component={LoginPageRoute} />
        <SentryRoute
          path="/auth/registration"
          component={RegistrationPageRoute}
        />
        <SentryRoute
          path="/auth/forgot-password"
          component={ForgotPasswordPageRoute}
        />
        <SentryRoute
          path="/auth/send-verification"
          component={SendVerificationRoute}
        />
        <SentryRoute
          path="/auth/confirm-password"
          component={ConfirmPasswordPageRoute}
        />
        <SentryRoute
          path="/auth/emailConfirm"
          component={EmailConfirmPageRoute}
        />
        <SentryRoute
          path="/auth/resend-email"
          component={ResendEmailPageRoute}
        />
        <SentryRoute path="/logout" component={LogoutPageRoute} />

        <Layout>
          <Suspense fallback={<LayoutSplashScreen />}>
            <Switch>
              <SentryRoute
                path="/keyword-spy"
                component={KeywordSpyPageRoute}
              />
              <SentryRoute
                path="/asin-sales-v2"
                component={AsinSalesV2PageRoute}
              />
              <SentryRoute
                path="/download-report"
                component={DownloadReportPageRoute}
              />
              <SentryRoute
                path="/competitor-analysis"
                component={CompetitorAnalysisPageRoute}
              />
              <SentryRoute
                path="/seasonality"
                component={SeasonalityPageRoute}
              />
              <SentryRoute
                path="/asin-insights"
                component={AsinInsightsPageRoute}
              />
              <SentryRoute
                exact
                path="/search-by-asin"
                component={SearchByAsinPageRoute}
              />
              <SentryRoute
                exact
                path="/asin-analysis"
                component={AsinAnalysisPageRoute}
              />
              <SentryRoute
                exact
                path="/multiple-asins-analysis"
                component={MultipleAsinsAnalysisPageRoute}
              />
              <SentryRoute
                exact
                path="/keywords-relevancy"
                component={KeywordsRelevancyPageRoute}
              />
              <SentryRoute
                exact
                path="/suggested-keywords"
                component={SuggestedKeywordsPageRoute}
              />
              <SentryRoute
                exact
                path="/change-password"
                component={ChangePasswordPageRoute}
              />
              <SentryRoute
                exact
                path="/projects"
                component={ProjectsPageRoute}
              />
              <SentryRoute
                exact
                path="/projects/:id/:tab"
                component={ProjectDetailsPageRoute}
              />
              <SentryRoute
                exact
                path="/projects/:id/:tab/:primaryAsin"
                component={ProjectDetailsPrimaryAsinPageRoute}
              />
              {/* <SentryRoute exact path="/settings/:tab" component={SettingsPageRoute} /> */}
              <SentryRoute
                exact
                path="/settings/billing"
                component={BillingPageRoute}
              />
              {isSettingConnectionMenuFeatureEnabled && (
                <SentryRoute
                  exact
                  path="/settings/connections"
                  component={ConnectionsPageRoute}
                />
              )}
              <SentryRoute
                exact
                path="/settings/user-profile"
                component={UserProfilePageRoute}
              />
              <SentryRoute
                exact
                path="/settings/company-profile"
                component={CompanyProfilePageRoute}
              />
              {isTeamMenuFeatureEnabled && (
                <SentryRoute
                  exact
                  path="/settings/team"
                  component={TeamPageRoute}
                />
              )}
              {/* <SentryRoute exact path="/settings/team-test" component={TeamPageTestRoute} /> */}
              <SentryRoute
                exact
                path="/plans"
                component={UpgradePlanPageRoute}
              />
              <SentryRoute
                exact
                path="/no-subscription"
                component={NoSubscriptionPageRoute}
              />
              <SentryRoute
                exact
                path="/network-error"
                component={NetworkErrorPageRoute}
              />
              <SentryRoute
                exact
                path="/access-denied"
                component={AccessDeniedPageRoute}
              />
              <SentryRoute
                path="/subscription-plans"
                component={SubscriptionPlansPageRoute}
              />
              <SentryRoute
                path="/complete-purchase"
                component={CompletePurchasePageRoute}
              />
              <Redirect to="/keyword-spy" />
            </Switch>
          </Suspense>
        </Layout>
      </Switch>
    </LayoutContextProvider>
  );
});
