import React from 'react';
import { makeStyles, Tooltip as MuiTooltip } from '@material-ui/core';
import clsx from 'clsx';

import { Icon } from 'app/components/Material/Icon';

const useStyles = makeStyles(theme => ({
  wrapper: {
    display: 'inline-block',
    '& .Info-Icon': {
      fontSize: 14,
      width: 14,
      height: 14,
      '&::before': {
        color: theme.tertiaryTextColor,
      },
    },
  },
  tooltip: {
    maxWidth: 280,
    color: 'rgba(255, 255, 255, 0.8)',
    boxShadow:
      '0px 2px 3px rgba(0, 0, 0, 0.2), 0px 0px 12px rgba(0, 0, 0, 0.15)',
    backgroundColor: theme.primaryTextColor,
    padding: 16,
    fontSize: 14,
    borderRadius: 6,
  },
  popper: {
    zIndex: 10003,
  }
}));

export const Tooltip = props => {
  const { children, className, showInfoIcon = false, placement, ...args } = props;
  const classes = useStyles();

  return (
    <MuiTooltip
      {...args}
      placement={placement}
      classes={{
        tooltip: clsx(classes.tooltip, className),
        popper: classes.popper,
      }}
    >
      <span className={classes.wrapper}>
        {children}
        {showInfoIcon && <Icon name="info" className="Info-Icon" />}
      </span>
    </MuiTooltip>
  );
};
