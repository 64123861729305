import { makeStyles } from '@material-ui/core';

export const useBillingStyles = makeStyles((theme) => ({
  header: {
    paddingBottom: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: '1px solid var(--lines-1st-pr, rgba(46, 49, 94, 0.16))',
    [theme.breakpoints.down('sm')]: {
      marginTop: '50px',
    },
  },
  headerLabel: {
    fontWeight: 800,
    fontSize: '24px',
    lineHeight: '32px',
    color: '#000000',
  },
  sectionTitle: {
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '20px',
    marginBottom: '12px',
    color: '#333333',
  },
  title: {
    fontWeight: 600,
    fontSize: 24,
    color: '#333147',
  },
  cardTitle: {
    fontWeight: 600,
    fontSize: 14,
  },
  subscriptionName: {
    fontSize: 16,
    color: '#FFFFFF',
    fontWeight: 600,
  },
  infoCard: {
    backgroundColor: '#787CF0',
    width: '100%',
    padding: 30,
    borderRadius: 6,
  },
  price: {
    fontSize: 60,
    color: '#FFFFFF',
    fontWeight: 600,
  },
  priceSubText: {
    fontSize: 22,
    color: '#FFFFFF',
    fontWeight: 600,
  },
  priceYearly: {
    fontSize: 16,
    fontWeight: 100,
    color: '#FFFFFF',
  },
  couponBoldText: {
    color: '#FFFFFF',
    fontSize: 14,
    fontWeight: 700,
    textAlign: 'left',
  },
  couponText: {
    color: '#FFFFFF',
    fontSize: 14,
    fontWeight: 400,
    marginLeft: 2,
    textAlign: 'left',
  },
  couponBtn: {
    background: 'none',
    border: 0,
    padding: 0,
    margin: '2px 0 0 2px',
  },
  smallTextBold: {
    color: 'rgba(247, 247, 254, 0.70)',
    fontSize: 14,
    fontWeight: 700,
  },
  smallText: {
    color: '#fff',
    fontSize: 14,
    fontWeight: 300,
  },
  manageBtn: {
    color: '#fff',
    background: 'none',
    fontSize: 14,
    fontWeight: 300,
    border: 0,
    margin: 0,
    padding: 0,
  },
  methodTitle: {
    fontSize: 15,
    fontWeight: 600,
    marginLeft: 12,
    textTransform: 'capitalize',
  },
  addPaymentMethodBtn: {
    background: 'none',
    border: 0,
    color: '#787CF0',
    fontSize: 16,
    fontWeight: 600,
  },
  container: {
    margin: '0 auto',
  },
  rowContainer: {
    marginTop: '32px',
  },
  planTitleBox: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '24px',
  },
  headerTitle: {
    color: '#000000',
    fontWeight: 800,
    fontSize: '20px',
    lineHeight: '24px',
    [theme.breakpoints.only('xs')]: {
      fontSize: '16px',
    },
  },
  row: {
    display: 'flex',
    gap: '20px',
    [theme.breakpoints.only('xs')]: {
      flexDirection: 'column',
    },
  },
  colLeft: {
    width: '70%',

    [theme.breakpoints.only('xs')]: {
      width: '100%',
      borderRight: 'none',
      borderBottom: '1px solid #DEDEE5',
    },
  },
  usageContainer: {
    border: '1px solid #DEDEE5',
    borderRadius: '16px',
    padding: '24px',
  },
  colRight: {
    width: '30%',

    [theme.breakpoints.only('xs')]: {
      width: '100%',
    },

    summaryContainer: {
      border: '1px solid #DEDEE5',
      borderRadius: '16px',
      padding: '24px',
    },
  },
  infoRow: {
    marginBottom: '24px',
    '&:last-child': {
      marginBottom: 0,
    },
  },
  infoGroup: {
    width: '100%',
  },
  infoTitle: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '20px',
    gap: '2px',
    color: '#666666',
    marginBottom: '4px',
  },
  infoSummary: {
    fontWeight: 800,
    fontSize: '20px',
    lineHeight: '24px',
    padding: '4px 0 16px',
    color: '#424242',
    [theme.breakpoints.only('xs')]: {
      fontSize: '16px',
      color: '#333333',
    },
  },
  changeButton: {
    [theme.breakpoints.only('xs')]: {
      '& > button': {
        width: '100%',
      },
    },
  },
  paymentLabel: {
    paddingBottom: '8px',
    color: '#000000',
    fontWeight: 800,
    fontSize: '16px',
    lineHeight: '24px',
    [theme.breakpoints.only('xs')]: {
      fontSize: '16px',
    },
  },
  paymentMethodContainer: {
    border: '1px solid #DEDEE5',
    borderRadius: '16px',
  },
  paymentDetail: {
    padding: '16px',
    marginBottom: '8px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
  },
  paymentTitle: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '20px',
    color: '#000000',
    letterSpacing: '0%',
    verticalAlign: 'middle',
  },
  paymentBrand: {
    textTransform: 'capitalize',
    paddingRight: '5px',
  },
  paymentExpires: {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '30px',
    color: '#8C8C8C',
  },
  buttonCustom: {
    height: 52,
    padding: '8px',
    display: 'flex',
    margin: '0px 2px 2px 2px',
    justifyContent: 'flex-end',
    backgroundColor: '#F9F9F9',
    borderBottomLeftRadius: '12px',
    borderBottomRightRadius: '12px',
    [theme.breakpoints.only('xs')]: {
      width: '100%',
      '& > button': {
        width: 'auto',
      },
    },
  },
  blankSection: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 'calc(100vh - 164px)',
    flexDirection: 'column',
  },
  blankText: {
    fontSize: '24px',
    textAlign: 'center',
    fontWeight: 800,
    color: '#333333',
  },
  buttonChangePlan: {
    display: 'flex',
    justifyContent: 'end',
    marginTop: '20px',
  },
  buttonInvite: {
    fontSize: '16px',
    height: '48px',
    lineHeight: '48px',
  },
  planStatusContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '16px',
  },
  planStatusLeft: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
  },
  planStatusBadge: {
    borderRadius: '4px',
    fontSize: '12px',
    fontWeight: 600,
    padding: '1px 6px',
  },
  planActions: {
    display: 'flex',
    gap: '8px',
  },
  statsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '2px',
    '&.disabled': {
      opacity: 0.5,
      pointerEvents: 'none',
    },
  },
  statsNumbers: {
    display: 'flex',
    gap: '4px',
    alignItems: 'baseline',
    '& > span': {
      fontWeight: 700,
      fontSize: '16px',
      lineHeight: '20px',
      color: '#333333',
      '&:nth-child(2)': {
        color: '#666666',
      },
    },
  },
  historicalData: {
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '20px',
    color: '#333333',
  },
  warningText: {
    color: '#E24F4F',
    fontSize: '12px',
    fontWeight: 600,
  },
  statusMessage: {
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0%',
    color: '#666666',
    marginTop: '8px',
  },
}));

export const useStatsClass = makeStyles({
  title: {
    color: '#333147',
    fontSize: 25,
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center',
  },
  description: {
    color: '#7B7A88',
    fontSize: 14,
    fontWeight: 'bold',
  },
});

export const usePaymentStyles = makeStyles((theme) => ({
  card: {
    padding: 0,
    margin: 0,
    minWidth: 548,
    maxWidth: 448,
    borderRadius: '12px',
    boxShadow: '0px 2px 4px rgb(0 0 0 / 20%), 0px 0px 20px rgb(0 0 0 / 15%)',
    [theme.breakpoints.only('xs')]: {
      minWidth: 'unset',
      margin: 'auto 0 0 0',
    },
  },
  text: {
    color: '#333147',
    fontWeight: 'bold',
    fontSize: 14,
  },
  paymentSummary: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: '16px',

    '& > img': {
      width: '24px',
      marginRight: '8px',
    },

    '& > span': {
      fontWeight: 400,
      fontSize: '14px',
      color: '#333333',
    },
  },
  purchaseCardInput: {
    '& > div:nth-child(2)': {
      margin: '4px 0 12px',
      border: '1px solid rgba(46,49,94,.16)',
      borderRadius: '8px',
      boxShadow: 'none',
      padding: '14px',
    },
  },
  purchaseCardLabel: {
    color: '#999',
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: '20px',
  },
  purchaseCardRow: {
    display: 'flex',
    gap: '16px',
    '& > div': {
      width: '50%',
    },
  },
  countryLabel: {
    paddingBottom: '4px',
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: '20px',
    color: '#999999',
  },
  countryDropdown: {
    '& > div > div': {
      '& > div:nth-child(1)': {
        padding: '14px 36px 14px 12px',
      },
    },
  },
  dialogContent: {
    padding: '0 !important',
  },
  paymentContent: {
    padding: '16px 24px',
    borderBottom: '1px solid rgba(46, 49, 94, 0.16)',
    [theme.breakpoints.only('xs')]: {
      padding: '22px 16px',
    },
  },
  paymentTitle: {
    fontWeight: 800,
    fontSize: '16px',
    lineHeight: '24px',
    color: '#333333',
    paddingBottom: '16px',
  },
  buttonGroup: {
    padding: '14px 16px',
    display: 'flex',
    justifyContent: 'end',
  },
  formTitle: {
    padding: '16px 24px 0',
    color: '#333333',
    fontSize: '16px',
    fontWeight: 800,
    lineHeight: '24px',
    paddingBottom: '16px',
  },
  formSummary: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 24px 16px',
  },
  purchaseSecurityText: {
    color: '#333333',
    fontSize: '14px',
    fontWeight: 400,
  },
  summaryIcon: {
    width: '24px',
    marginRight: '8px',
  },
}));
