import { call, put, takeLatest } from 'redux-saga/effects';
import stringify from 'qs-stringify';

import config from 'config';
import { getJwt, getUsersEmail } from 'app/crud/auth.crud';
import { request, getHeaders } from 'app/utils/fetch';
import { notify } from 'app/Services/app/notificationService';
import { actions as AuthActions } from 'app/store/ducks/auth/actions';
import { actions } from './actions';
import { actionTypes } from './actionTypes';

export function* watchStripeSaga() {
  yield takeLatest(actionTypes.RetrievePaymentInfo, apiRetrievePaymentInfoSaga);
  yield takeLatest(actionTypes.FetchListProduct, apiFetchListProductSaga);
  yield takeLatest(actionTypes.FetchListInvoices, apiFetchListInvoicesSaga);
  yield takeLatest(actionTypes.FetchInvoicePayment, apiFetchInvoicePaymentSaga);
  yield takeLatest(actionTypes.GetUserPlan, apiGetUserPlanSaga);
  yield takeLatest(actionTypes.CreateSubscription, apiCreateSubscriptionSaga);
  yield takeLatest(
    actionTypes.RetryInvoiceWithNewPaymentMethod,
    apiRetryInvoiceWithNewPaymentMethodSaga,
  );
  yield takeLatest(actionTypes.UpdateSubscription, apiUpdateSubscriptionSaga);
  yield takeLatest(
    actionTypes.RetrieveUpcomingInvoice,
    apiRetrieveUpcomingInvoiceSaga,
  );
  yield takeLatest(actionTypes.CancelSubscription, apiCancelSubscriptionSaga);
  yield takeLatest(actionTypes.GrantAccess, apiGrantAccessSaga);
  yield takeLatest(
    actionTypes.UpdateUserPaymentMethod,
    apiUpdateUserPaymentMethodSaga,
  );
  yield takeLatest(actionTypes.GetClientSecret, apiGetClientSecretSaga);
  yield takeLatest(actionTypes.GetInvoices, apiGetInvoicesSaga);
  yield takeLatest(
    actionTypes.ReactivateSubscription,
    apiReactivateSubscriptionSaga,
  );
  yield takeLatest(actionTypes.PayLastInvoice, apiPayLastInvoiceSaga);
  yield takeLatest(actionTypes.ApplyCoupon, apiApplyCouponSaga);
  yield takeLatest(actionTypes.VerifyCoupon, apiVerifyCouponSaga);
  yield takeLatest(actionTypes.GetInvoiceDetails, apiGetStripeDetails);
  yield takeLatest(actionTypes.GetCustomerInfo, apiGetCustomerInfo);
  yield takeLatest(actionTypes.UpdateCustomerInfo, apiUpdateCustomerInfo);
  yield takeLatest(actionTypes.RetryPayment, apiRetryPaymentSaga);
  yield takeLatest(
    actionTypes.StopRequestedCancellation,
    apiStopRequestedCancellationSaga,
  );
  yield takeLatest(
    actionTypes.StopRequestedDowngrade,
    apiStopRequestedDowngradeSaga,
  );
}

function* apiRetrievePaymentInfoSaga(action) {
  yield put(actions.setSendingStatus(true));
  const headers = yield getHeaders();
  const apiRes = yield call(() =>
    request(headers).get('/retrieve-customer-payment'),
  );
  if (apiRes && apiRes.status === 'OK') {
    yield put(actions.updatePaymentInfo(apiRes.data));
    if (apiRes.data)
      yield put(AuthActions.setUserPlanFromDB(apiRes.data.userPlan));
  } else {
    yield put(actions.fetchListProduct());
  }
  yield put(actions.setSendingStatus(false));
}

function* apiFetchListProductSaga(action) {
  const headers = yield getHeaders();
  const apiRes = yield call(() => request(headers).get('/products'));
  if (apiRes) {
    yield put(actions.updateListProduct(apiRes.data));
  }
}

function* apiFetchListInvoicesSaga(action) {
  const headers = yield getHeaders();
  yield put(actions.setSendingInvoices(true));
  const apiRes = yield call(() => request(headers).get('/invoices'));
  if (apiRes) {
    yield put(actions.updateListInvoices(apiRes));
  }
  yield put(actions.setSendingInvoices(false));
}

function* apiFetchInvoicePaymentSaga(action) {
  const headers = yield getHeaders();
  yield put(actions.setSendingPayment(true));
  const apiRes = yield call(() =>
    request(headers).get(`/get-payment-method?allId=${action.payload}`),
  );
  if (apiRes) {
    yield put(actions.updateInvoicePayment(apiRes));
  }
  yield put(actions.setSendingPayment(false));
}

function* apiGetUserPlanSaga(action) {
  const headers = yield getHeaders();
  const apiRes = yield call(() =>
    request(headers).get(`/user-plan?priceId=${action.payload}`),
  );
  if (apiRes) {
    yield put(actions.updateProductResource(apiRes.data));
  }
}

function* apiCreateSubscriptionSaga(action) {
  yield put(actions.setSendingStatus(true));
  yield put(actions.setSubscribeNewPlan(true));
  const headers = yield getHeaders();
  const email = yield getUsersEmail();
  const requestPayload = {
    priceId: action.payload.priceId,
    isTrial: action.payload.isTrial,
    email: email,
  };
  const apiRes = yield call(() =>
    request(headers).post('/create-subscription', stringify(requestPayload)),
  );

  if (!apiRes || apiRes.status === 'FAILED') {
    notify.error(apiRes.error);
    yield put(actions.setSendingStatus(false));
  } else if (apiRes.session) {
    window.open(apiRes.session.url, '_self');
  } else {
    yield put(actions.retrievePaymentInfo());
    yield put(actions.setSendingStatus(false));
    yield put(AuthActions.setExpiredPlanError(false));
    yield put(actions.getUserPlan(apiRes.data.items.data[0].price.id));
    yield put(
      actions.grantAccess({
        subscriptionId: apiRes.data.id,
      }),
    );
    yield put(actions.setSubscribeNewPlan(false));
  }
}

function* apiRetryInvoiceWithNewPaymentMethodSaga(action) {
  yield put(actions.setSendingStatus(true));
  let _err402 = false;
  let ConfirmSuccess = false;
  const token = yield getJwt();
  const requestPayload = {
    customerId: action.payload.customerId,
    paymentMethodId: action.payload.paymentMethodId,
    invoiceId: action.payload.invoiceId,
  };
  const apiRes = yield call(() => {
    return fetch(config.api.SERVER_URL + '/retry-invoice', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: `Bearer ${token}`,
        'x-parent-user-id': localStorage.getItem('x-parent-user-id') || '',
        'x-parent-user-email':
          localStorage.getItem('x-parent-user-email') || '',
      },
      body: stringify(requestPayload),
    })
      .then((response) => {
        if (response.status === 402) {
          _err402 = true;
        }
        return response.json();
      })
      .then((result) => {
        if (result.error) {
          if (result.error) {
            notify.error(result.error);
            throw result;
          }
        }
        return result;
      })
      .then((result) => {
        return {
          invoice: result.data,
          paymentMethodId: action.payload.paymentMethodId,
          priceId: action.payload.priceId,
        };
      })
      .catch((error) => {
        // An error has happened. Display the failure to the user here.
        // We utilize the HTML element we created.
        // displayError(error);
      });
  });
  if (!_err402) {
    const { invoice, paymentMethodId } = apiRes;
    let paymentIntent = invoice.payment_intent;
    const stripe = window.stripe;
    yield call(() => {
      return stripe
        .confirmCardPayment(paymentIntent.client_secret, {
          payment_method: paymentMethodId,
        })
        .then((result) => {
          if (result.error) {
            notify.error(`Confirm card payment error: ${result.error.message}`);
            // Start code flow to handle updating the payment details.
            // Display error message in your UI.
            // The card was declined (i.e. insufficient funds, card has expired, etc).
            throw result;
          } else {
            if (result.paymentIntent.status === 'succeeded') {
              ConfirmSuccess = true;
              localStorage.removeItem('latestInvoiceId');
              localStorage.removeItem('latestInvoicePaymentIntentStatus');
              return {
                priceId: action.payload.priceId,
                invoice: invoice,
                paymentMethodId: action.payload.paymentMethodId,
              };
            }
          }
        })
        .catch((error) => {
          console.log('error fetch', error);
        });
    });
    if (ConfirmSuccess) {
      yield put(
        actions.grantAccess({
          subscriptionId: invoice.subscription,
          customerId: invoice.customer,
        }),
      );
    }
  }
  yield put(actions.setSendingStatus(false));
}

function* apiUpdateSubscriptionSaga(action) {
  const headers = yield getHeaders();
  yield put(actions.setSendingStatus(true));
  const requestPayload = {
    newPriceId: action.payload.priceId,
    isUpgrading: action.payload.isUpgrading,
  };
  const apiRes = yield call(() =>
    request(headers).post('/update-subscription', stringify(requestPayload)),
  );
  if (!apiRes || apiRes.status === 'FAILED') {
    notify.error(apiRes.error);
    yield put(actions.setSendingStatus(false));
  } else if (apiRes.session) {
    window.open(apiRes.session.url, '_self');
  } else {
    yield put(actions.retrievePaymentInfo());
    yield put(actions.setSendingStatus(false));
    yield put(actions.updateSuccess(false));
    yield put(AuthActions.setExpiredPlanError(false));
    yield put(actions.getUserPlan(apiRes.data.items.data[0].price.id));
    yield put(
      actions.grantAccess({
        subscriptionId: apiRes.data.id,
      }),
    );
  }
}

function* apiRetrieveUpcomingInvoiceSaga(action) {
  yield put(actions.setSendingStatus(true));
  const token = yield getJwt();
  const requestPayload = {
    newPriceId: action.payload.priceId,
  };
  const apiRes = yield call(() => {
    return fetch(config.api.SERVER_URL + '/retrieve-upcoming-invoice', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: `Bearer ${token}`,
        'x-parent-user-id': localStorage.getItem('x-parent-user-id') || '',
        'x-parent-user-email':
          localStorage.getItem('x-parent-user-email') || '',
      },
      body: stringify(requestPayload),
    })
      .then((response) => {
        return response.json();
      })
      .then((invoice) => {
        return invoice;
      })
      .catch((err) => {
        console.log(err);
      });
  });
  if (apiRes) {
    yield put(actions.updateUpComingInvoice(apiRes));
  }
  yield put(actions.setSendingStatus(false));
}

function* apiCancelSubscriptionSaga(action) {
  yield put(actions.setSendingStatus(true));
  try {
    const requestPayload = {
      subscriptionId: action.payload.subscriptionId,
    };
    const headers = yield getHeaders();
    const apiRes = yield call(() =>
      request(headers).post('/cancel-subscription', stringify(requestPayload)),
    );
    if (apiRes && apiRes.status === 'FAILED') {
      notify.error(apiRes.message);
    }

    window.open(apiRes.session.url, '_self');
  } catch (error) {
    notify.error('An error occurred while canceling the subscription.');
  } finally {
    yield put(actions.setSendingStatus(false));
  }
}

function* apiGrantAccessSaga(action) {
  const requestPayload = {
    subscriptionId: action.payload.subscriptionId,
  };
  const headers = yield getHeaders();
  const apiRes = yield call(() =>
    request(headers).post('/grant-access', stringify(requestPayload)),
  );
  if (apiRes) {
    yield put(actions.getUserPlan(apiRes.data.items.data[0].price.id));
  }
}

function* apiUpdateUserPaymentMethodSaga(action) {
  yield put(actions.isUpdatePayment(true));
  const requestPayload = action.payload;
  const headers = yield getHeaders();
  const apiRes = yield call(() =>
    request(headers).post('/update-card', stringify(requestPayload)),
  );
  if (apiRes && apiRes.status === 'FAILED') {
    yield put(actions.isUpdatePayment(false));
    notify.error(apiRes.error);
  } else {
    yield put(actions.isUpdatePayment(false));
    yield put(actions.setClientSecret(''));
    yield put(actions.retrievePaymentInfo());
    // window.open(apiRes.session.url, '_self');
    notify.success('Payment information successfully updated');
  }
}

function* apiGetClientSecretSaga(action) {
  yield put(actions.setSendingStatus(true));
  const headers = yield getHeaders();
  const apiRes = yield call(() => request(headers).get('/client-secret'));
  if (apiRes && apiRes.client_secret !== '') {
    yield put(actions.setClientSecret(apiRes.client_secret));
  }
  yield put(actions.setSendingStatus(false));
}

function* apiGetInvoicesSaga(action) {
  yield put(actions.setSendingStatus(true));
  const headers = yield getHeaders();
  const apiRes = yield call(() => request(headers).get('/invoices'));
  if (apiRes) {
    yield put(actions.updateInvoices(apiRes));
  }
  yield put(actions.setSendingStatus(false));
}

function* apiReactivateSubscriptionSaga(action) {
  yield put(actions.setSendingStatus(true));
  const requestPayload = action.payload;
  const headers = yield getHeaders();
  const apiRes = yield call(() =>
    request(headers).post(
      '/reactivate-subscription',
      stringify(requestPayload),
    ),
  );
  if (apiRes && apiRes.status !== 'FAILED') {
    yield put(actions.reactivateSuccess(true));
    yield put(AuthActions.setExpiredPlanError(''));
  }
  if (apiRes && apiRes.status === 'FAILED') {
    notify.error(apiRes.error);
  }
  if (apiRes.status === 'OK' && apiRes.session) {
    window.open(apiRes.session.url, '_self');
  }
  yield put(actions.retrievePaymentInfo());
  yield put(actions.setSendingStatus(false));
  yield put(actions.reactivateSuccess(false));
}

function* apiPayLastInvoiceSaga(action) {
  yield put(actions.setSendingStatus(true));
  const headers = yield getHeaders();
  const apiRes = yield call(() => request(headers).post('/pay'));
  if (apiRes && apiRes.status === 'success') {
    yield put(AuthActions.setExpiredPlanError(''));
    notify.success('Pay last invoice successfully');
    yield put(actions.retrievePaymentInfo());
  } else {
    notify.error(apiRes.error);
  }
  yield put(actions.setSendingStatus(false));
}

function* apiApplyCouponSaga(action) {
  yield put(actions.setSendingStatus(true));
  const requestPayload = action.payload;
  const headers = yield getHeaders();
  const apiRes = yield call(() =>
    request(headers).post('/coupon', stringify(requestPayload)),
  );
  if (apiRes && apiRes.status === 'OK') {
    notify.success(apiRes.message);
    yield put(actions.retrievePaymentInfo());
  } else {
    notify.error(apiRes.error);
  }
  yield put(actions.setSendingStatus(false));
}

function* apiVerifyCouponSaga(action) {
  const requestPayload = action.payload;
  const headers = yield getHeaders();
  const apiRes = yield call(() =>
    request(headers).post('/verify-coupon', stringify(requestPayload)),
  );
  if (apiRes && apiRes.status === 'OK') {
    notify.success(apiRes.message);
    action.payload.onSuccess(apiRes.description);
  } else {
    notify.error(apiRes.error);
    action.payload.onError();
  }
}

function* apiGetStripeDetails(action) {
  const requestPayload = {
    invoiceId: action?.payload,
  };
  const headers = yield getHeaders();
  const apiRes = yield call(() =>
    request(headers).post('/invoice-details', stringify(requestPayload)),
  );
  if (!apiRes || apiRes.status === 'FAILED') {
    notify.error(apiRes.error);
    yield put(actions.setSendingStatus(false));
  } else {
    const { source } = apiRes;
    if (source) {
      window.location.href = source;
    }
  }
}

function* apiGetCustomerInfo() {
  const headers = yield getHeaders();
  const apiRes = yield call(() => request(headers).get('/get-stripe-customer'));
  if (apiRes) {
    yield put(actions.setCustomerInfo(apiRes.data));
  }
}

function* apiUpdateCustomerInfo(action) {
  const payload = action.payload;
  const headers = yield getHeaders();
  const apiRes = yield call(() =>
    request(headers).post('/update-customer-invoice-info', stringify(payload)),
  );
  if (apiRes && apiRes.status === 'OK') {
    yield put(actions.setCustomerInfo(apiRes.data));
    notify.success('You updated Company Profile info.');
  }
}

function* apiRetryPaymentSaga(action) {
  yield put(actions.setSendingStatus(true));
  const headers = yield getHeaders();

  const apiRes = yield call(() => request(headers).get('/retry-payment'));

  if (!apiRes || apiRes.status === 'FAILED') {
    notify.error(apiRes.error);
  }

  yield put(actions.setSendingStatus(false));

  // If there's a callback function, call it with the response
  if (action.callback) {
    action.callback(apiRes);
  }
}

function* apiStopRequestedCancellationSaga(action) {
  try {
    yield put(actions.setSendingStatus(true));
    const headers = yield getHeaders();
    const requestPayload = {
      subscriptionId: action.payload,
    };

    const apiRes = yield call(() =>
      request(headers).post(
        '/stop-requested-cancel-subscription',
        stringify(requestPayload),
      ),
    );

    if (apiRes && apiRes.status === 'OK') {
      yield put(actions.retrievePaymentInfo());
      notify.success('Subscription cancellation has been stopped');
    } else {
      notify.error(apiRes.error || 'Failed to stop subscription cancellation');
    }
  } catch (error) {
    console.error('Error stopping subscription cancellation:', error);
    notify.error('Failed to stop subscription cancellation');
  } finally {
    yield put(actions.setSendingStatus(false));
  }
}

function* apiStopRequestedDowngradeSaga(action) {
  try {
    yield put(actions.setSendingStatus(true));
    const headers = yield getHeaders();

    const apiRes = yield call(() =>
      request(headers).post('/stop-requested-downgrade-subscription'),
    );

    if (apiRes && apiRes.status === 'OK') {
      yield put(actions.retrievePaymentInfo());
      notify.success('Subscription downgrade has been stopped');
    } else {
      notify.error(apiRes.error || 'Failed to stop subscription downgrade');
    }
  } catch (error) {
    console.error('Error stopping subscription downgrade:', error);
    notify.error('Failed to stop subscription downgrade');
  } finally {
    yield put(actions.setSendingStatus(false));
  }
}
