import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { actions } from 'app/store/ducks/stripe/actions';
import doneIcon from 'app/assets/images/icons/done-icon.jpg';
import styles from './SubscriptionPlans.module.scss';
import PaymentMethodModal from "../../settings/billing/modals/PaymentMethodModal";
import {Elements} from "@stripe/react-stripe-js";
import DowngradeConfirmationModal from './modals/DowngradeConfirmationModal';

const Plans = ({ product, userPlan, allRestrictions, selectedInterval = 'month' }) => {
  const dispatch = useDispatch();

  const paymentInfo = useSelector(state => state.stripe.paymentInfo);
  const sending = useSelector(state => state.stripe.sending);
  const price = product.prices.find(item => item.interval === selectedInterval);

  const projects = useSelector(state => state.auth.userDetails.projects || 0);
  const asinAmount = useSelector(state => state.auth.userDetails.asinAmount || 0);
  const keywordsAmount = useSelector(state => state.auth.userDetails.keywordsAmount || 0);

  const [showBillingForm, setShowBillingForm] = useState(null);
  const [showDowngradeModal, setShowDowngradeModal] = useState(false);

  const expiryDate = paymentInfo
    ? new Date(paymentInfo.expiry_date)
    : new Date();
  const isExpired = moment(expiryDate) < moment();
  const isSelectedPlan = product.name === userPlan?.name;
  const cancelAtPeriodEnd = paymentInfo
    ? paymentInfo.cancel_at_period_end
    : false;

  const getLimitData = () => {
    const limitData = [];
    Object.keys(product.restrictions).forEach(item => {
      const restriction = allRestrictions[item];
      if(restriction) {
        let content;
        if (restriction.type === 'boolean' && !product.restrictions[item]) {
          content = restriction.default_message;
        } else {
          // eslint-disable-next-line no-template-curly-in-string
          content = restriction.custom_message.replace('${limit}', product.restrictions[item]);
        }
        limitData.push({
          tooltip: restriction.tooltip,
          content,
          order: restriction.order,
        });
      }
    });
    return [...limitData].sort((a, b) => a.order - b.order);
  };

  const renderTextButton = () => {
    if (!userPlan || !userPlan.price) return 'Start Trial';
    if (isExpired || cancelAtPeriodEnd) return 'Subscribe';
    if (price.price < userPlan?.price) {
      return 'Downgrade';
    } else if (price.price === userPlan?.price) {
        return 'Cancel';
    } else {
      return 'Upgrade';
    }
  };

  const handleStartTrial = currentProduct => {
      localStorage.setItem('currentProduct', JSON.stringify({...currentProduct, selectedInterval}));
      const currentPrice = currentProduct ? currentProduct.prices.find(item => item.interval === selectedInterval) : null;
      dispatch(
          actions.createSubscription({
              priceId: currentPrice.priceId,
              isTrial: true,
          }),
      );
  };

    const openPaymentForm = () => {
        new Promise(res => {
            dispatch(actions.getClientSecret());
            setTimeout(() => {
                res('');
            }, 2000);
        }).then(() => {
            setShowBillingForm(true);
        });
    };

  const checkHasExceededLimits = (newPlan) => {
    const newRestrictions = newPlan.restrictions;
    return (
      (projects > (newRestrictions.projectsLimit || 999)) ||
      (asinAmount > (newRestrictions.asinsLimit || 0)) ||
      (keywordsAmount > (newRestrictions.keywordsLimit || 0))
    );
  };

  const handleClickButton = () => {
    if (!userPlan || !userPlan.price) {
      handleStartTrial(product);
    } else {
      if (isSelectedPlan) {
        if (!isExpired && !cancelAtPeriodEnd) {
          dispatch(
              actions.cancelSubscription({
                  subscriptionId: paymentInfo.subscriptionId,
              }),
          );
        } else {
          if(paymentInfo.card) {
            dispatch(
                actions.reactivateSubscription({
                    subscriptionId: paymentInfo.subscriptionId,
                }),
            );
          } else {
            openPaymentForm();
          }
        }
      } else {
        if (price.price < userPlan?.price && checkHasExceededLimits(product)) {
          setShowDowngradeModal(true);
        } else {
          if(paymentInfo.card) {
            dispatch(
                actions.updateSubscription({
                    priceId: price.priceId,
                    isUpgrading: userPlan?.price < price.price,
                }),
            );
          } else {
            openPaymentForm();
          }
        }
      }
    }
  };

  const handleDowngradeConfirm = () => {
    setShowDowngradeModal(false);
    if(paymentInfo.card) {
      dispatch(
        actions.updateSubscription({
          priceId: price.priceId,
          isUpgrading: false,
        }),
      );
    } else {
      openPaymentForm();
    }
  };

  return (
    <>
      <div className={styles.plans}>
        <div className={styles.plansTitle}>
          {product.name}{' '}
          {product.tags && product.tags.length && product.tags.map(tag => (
            <div className={styles.plansMost}>{tag}</div>
          ))}
        </div>
        {/* {product.name === 'Enterprise' ? (
          <div className={styles.plansTopBox}>
            <span className={styles.plansText}>
              For agencies and large businesses contact us for pricing on custom
              plans to suit your needs.
            </span>
            <div className={styles.plansLetTask}>
              Let's Talk <img src={arrowRight} alt="icon" />
            </div>
          </div>
        ) : ( */}
        <div
          className={`${styles.plansTopBox} ${userPlan && styles.unsetHeight}`}
        >
          <div className={styles.plansPrice}>
            {price.discounted_from && <span className={styles.plansOldPrice}>${price.discounted_from} </span>}
            <span className={styles.plansNewPrice}>
              ${price.price / 100}{' '}
            </span>
            <span className={styles.plansText}>/month</span>
          </div>
          <button
            onClick={handleClickButton}
            className={`btn ${styles.plansButton} ${sending &&
              styles.buttonDisable}`}
            disabled={sending}
          >
            {renderTextButton()}
          </button>
          {!userPlan && <div className={styles.plansSummary}>14 days free</div>}
        </div>
        {/* )} */}
        <div>
          <div className={styles.plansInclude}>Includes</div>
          {getLimitData().map((data, index) => (
              <div className={styles.plansOptions} key={index}>
                <img src={doneIcon} alt="icon" /> {data.content}
              </div>
            ))}
        </div>
      </div>

      <Elements stripe={window.stripe}>
        <PaymentMethodModal
            open={showBillingForm}
            onClose={(obj = {}) => {
              setShowBillingForm(false);
              if(isSelectedPlan) {
                  dispatch(
                      actions.reactivateSubscription({
                          subscriptionId: paymentInfo.subscriptionId,
                      }),
                  );
              } else {
                  dispatch(
                      actions.updateSubscription({
                          priceId: price.priceId,
                          isUpgrading: userPlan?.price < price.price,
                      }),
                  );
              }
            }}
        />
      </Elements>

      <DowngradeConfirmationModal
        open={showDowngradeModal}
        onClose={() => setShowDowngradeModal(false)}
        onConfirm={handleDowngradeConfirm}
        product={product}
        usage={{
          projects,
          asinAmount,
          keywordsAmount
        }}
        expiryDate={expiryDate}
      />
    </>
  );
};

export default Plans;
